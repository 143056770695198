<template>
  <TosListVuetify serviceName="registry" entityName="companies" :headers="headers" :prepareItemFunction="prepareItems">
    <template #customFilters="slotProps">
      <v-container fluid>
        <v-row>
          <v-col cols="3">
            <v-text-field :label="tLabel('codice')" v-model="slotProps.data.code"> </v-text-field>
          </v-col>
          <v-col cols="3">
            <v-select :label="tLabel('categoria')" v-model="slotProps.data.filtroCategoria" :items="listCategorie" />
          </v-col>
          <v-col cols="1">
            <v-checkbox small :label="tLabel('Cliente')" v-model="slotProps.data.cliente"  />
          </v-col>
          <v-col cols="1">
            <v-checkbox small :label="tLabel('Fornitore')" v-model="slotProps.data.fornitore"  />
          </v-col>
          <v-col cols="1">
            <v-checkbox small :label="tLabel('Prospect')" v-model="slotProps.data.prospect"  />
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template #editF="slotProps">
      <div v-if="slotProps.data" class="p-cardialog-content">
        <v-row>
          <v-col cols="3">
            <v-text-field :label="tLabelRequired('codice')" v-model="slotProps.data.code" :rules="[rules.required]" :error-messages="slotProps.errors.code" />
          </v-col>
          <v-col cols="3">
            <v-text-field
              :label="tLabelRequired('ragione_sociale')"
              v-model="slotProps.data.ragioneSociale"
              :rules="[rules.required]"
              :error-messages="slotProps.errors.ragioneSociale"
            />
          </v-col>
          <v-col cols="3">
            <v-text-field :label="tLabel('codice_cliente_ERP') + '*'" v-model="slotProps.data.codiceClienteErp" />
          </v-col>
          <v-col cols="3">
            <v-text-field :label="tLabel('codice_fornitore_ERP') + '*'" v-model="slotProps.data.codiceFornitoreErp" />
          </v-col>
          <v-col cols="3">
            <v-text-field :label="tLabel('EORI')" v-model="slotProps.data.eori" />
          </v-col>
          <v-col cols="3">
            <v-text-field :label="tLabel('Partita_IVA')" v-model="slotProps.data.partitaIva" />
          </v-col>
          <v-col cols="3">
            <v-text-field :label="tLabel('C.F.')" v-model="slotProps.data.cf" />
          </v-col>
        </v-row>
        <v-expansion-panels multiple v-model="panels">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <span>{{ tLabel("dati_anagrafici_amministrativi") }}</span></v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="3">
                  <v-text-field :label="tLabel('via')" v-model="slotProps.data.via" />
                </v-col>
                <v-col cols="3">
                  <v-text-field :label="tLabel('numero_civico')" :rules="[rules.numeric]" v-model="slotProps.data.numeroCivico" />
                </v-col>
                <v-col cols="3">
                  <v-text-field :label="tLabel('cap')" :rules="[rules.numeric]" v-model="slotProps.data.cap" />
                </v-col>
                <v-col cols="3">
                  <v-text-field :label="tLabel('comune')" v-model="slotProps.data.comune" />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <v-text-field :label="tLabel('paese')" v-model="slotProps.data.paese" />
                </v-col>
                <v-col cols="3">
                  <v-text-field :label="tLabel('mail')" v-model="slotProps.data.mail" />
                </v-col>
                <v-col cols="3">
                  <v-text-field :label="tLabel('telefono')" :rules="[rules.numeric]" v-model="slotProps.data.telefono" />
                </v-col>
                <v-col cols="3">
                  <v-text-field :label="tLabel('Fax')" :rules="[rules.numeric]" v-model="slotProps.data.fax" />
                </v-col>
                <v-col cols="3">
                  <v-text-field :label="tLabel('descrizioneCO') + ' (a cura di)'" v-model="slotProps.data.descrizioneCO" />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>
              <span>{{ tLabel("ruolo") }} </span></v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="2">
                  <v-checkbox :label="tLabel('cliente')" v-model="slotProps.data.cliente" :error-messages="slotProps.errors.cliente" />
                </v-col>
                <v-col cols="2">
                  <v-checkbox :label="tLabel('fornitore')" v-model="slotProps.data.fornitore" :error-messages="slotProps.errors.fornitore" />
                </v-col>
                <v-col cols="2">
                  <v-checkbox :label="tLabel('prospect')" v-model="slotProps.data.prospect" :error-messages="slotProps.errors.prospect" />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <span>{{ tLabel("categoria") }}</span></v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="2" v-for="categoria in listCategorie" :key="categoria.value">
                  <v-checkbox :label="categoria.text" :value="categoria.value" v-model="slotProps.data.listCategoriesId" />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </template>
  </TosListVuetify>
</template>

<script>
import TosListVuetify from "../../components/anagrafiche/TosListVuetify";
import FormatsMixins from "../../mixins/FormatsMixins";

export default {
  name: "Companies",
  data() {
    return {
      listRuoli: null,
      listCategorie: null,
      panels: [0, 1, 2, 3, 4],
      headers: [
        { text: "", value: "action" },
        { text: "Codice", value: "code" },
        { text: "Ragione Sociale", value: "ragioneSociale" },
        {
          text: "Cliente",
          value: "cliente",
          formatValue: "formatBoolean",
          align: "center",
          class: "header text-uppercase",
        },
        {
          text: "Fornitore",
          value: "fornitore",
          formatValue: "formatBoolean",
          align: "center",
          class: "header text-uppercase",
        },
        {
          text: "Prospect",
          value: "prospect",
          formatValue: "formatBoolean",
          align: "center",
          class: "header text-uppercase",
        },
        { text: "categoria", value: "listaCategioriaStringa" },
        { text: "Codice Cliente ERP", value: "codiceClienteErp" },
        { text: "Codice Fornitore ERP", value: "codiceFornitoreErp" },
        { text: "Codice EORI", value: "eori" },
        { text: "P. IVA", value: "partitaIva" },
        { text: "C.F.", value: "cf" },
      ],
    };
  },
  async mounted() {
    this.listCategorie = await this.$api.get(this.$apiConfiguration.BASE_PATH_REGISTRY + "companies/listCategories");
  },
  methods: {
    validate(item) {
      let errors = {};
      return errors;
    },
    prepareItems(item) {
      if (item.listCategoriesId == null) item.listCategoriesId = [];
    },
  },
  components: {
    TosListVuetify: TosListVuetify,
  },
  mixins: [FormatsMixins],
};
</script>
